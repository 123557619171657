import React, { useState, useEffect } from 'react';
import { X, Plus, Trash2, Download } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { FlashCardSet } from '../types';
import clsx from 'clsx';
import { detectLanguage } from '../utils/languageDetection';

interface CreateCardsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editingSet?: FlashCardSet | null;
}

interface CardInput {
  id?: string;
  front: string;
  back: string;
}

export default function CreateCardsModal({ isOpen, onClose, onSuccess, editingSet }: CreateCardsModalProps) {
  const { user } = useAuth();
  const [title, setTitle] = useState(editingSet?.name || 'New Flash Card Set');
  const [isPublic, setIsPublic] = useState(editingSet?.isPublic ?? true);
  const [cards, setCards] = useState<CardInput[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [groupName, setGroupName] = useState<string>(editingSet?.groupName || '');

  useEffect(() => {
    if (editingSet) {
      setTitle(editingSet.name);
      setIsPublic(editingSet.isPublic || false);
      setGroupName(editingSet.groupName || '');

      const fetchCards = async () => {
        const { data, error } = await supabase
          .from('flashcards')
          .select('*')
          .eq('set_id', editingSet.id);
          
        if (error) {
          console.error('Error fetching cards:', error);
          return;
        }
        
        setCards(data.map(card => ({
          id: card.id,
          front: card.word,
          back: card.definition
        })));
      };
      
      fetchCards();
    } else {
      setTitle('New Flash Card Set');
      setIsPublic(true);
      setGroupName('');
      setCards([
        { front: '', back: '' },
        { front: '', back: '' },
        { front: '', back: '' },
      ]);
    }
  }, [editingSet]);

  if (!isOpen) return null;

  const handleAddCard = () => {
    setCards([...cards, { front: '', back: '' }]);
  };

  const handleCardChange = (index: number, field: 'front' | 'back', value: string) => {
    const newCards = [...cards];
    newCards[index][field] = value;
    setCards(newCards);
  };

  const handleDelete = async () => {
    if (!editingSet || !user) return;
    
    if (!window.confirm('Are you sure you want to delete this flash card set? This action cannot be undone.')) {
      return;
    }

    try {
      setDeleting(true);
      setError('');

      const { error: deleteError } = await supabase
        .from('flashcard_sets')
        .delete()
        .eq('id', editingSet.id)
        .eq('created_by', user.id);

      if (deleteError) throw deleteError;

      onSuccess();
      onClose();
      window.location.reload();
    } catch (err) {
      console.error('Error deleting set:', err);
      setError(err instanceof Error ? err.message : 'Failed to delete set');
    } finally {
      setDeleting(false);
    }
  };

  const handleDownloadCSV = () => {
    if (!editingSet) return;

    // Create CSV content
    const csvContent = cards
      .map(card => `${groupName || ''},${title},${card.front},${card.back}`)
      .join('\n');
    const header = 'Group Name,Set Name,Word,Definition\n';
    const fullContent = header + csvContent;

    // Create and trigger download
    const blob = new Blob([fullContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${title.toLowerCase().replace(/\s+/g, '-')}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('Please sign in to create flash cards');
      return;
    }

    const validCards = cards.filter(card => card.front.trim() && card.back.trim());
    if (validCards.length === 0) {
      setError('Please add at least one card with both front and back filled out');
      return;
    }

    setLoading(true);
    setError('');

    try {
      if (editingSet) {
        // Update existing set
        const { error: setError } = await supabase
          .from('flashcard_sets')
          .update({
            name: title,
            is_public: isPublic,
            updated_at: new Date().toISOString(),
            group_name: groupName.trim() ? groupName : null,
          })
          .eq('id', editingSet.id);

        if (setError) throw setError;

        // Update or insert cards
        for (const card of validCards) {
          if (card.id) {
            // Update existing card
            const { error: cardError } = await supabase
              .from('flashcards')
              .update({
                word: card.front,
                definition: card.back,
                language: detectLanguage(card.front),
                updated_at: new Date().toISOString()
              })
              .eq('id', card.id);

            if (cardError) throw cardError;
          } else {
            // Insert new card
            const { error: cardError } = await supabase
              .from('flashcards')
              .insert({
                set_id: editingSet.id,
                word: card.front,
                definition: card.back,
                language: detectLanguage(card.front)
              });

            if (cardError) throw cardError;
          }
        }
      } else {
        // Create new set
        const { data: setData, error: createSetError } = await supabase
          .from('flashcard_sets')
          .insert({
            name: title,
            created_by: user.id,
            is_public: isPublic,
            group_name: groupName.trim() ? groupName : null
          })
          .select()
          .single();

        if (createSetError) throw createSetError;

        // Insert cards for this new set
        const cardsToInsert = validCards.map(card => ({
          set_id: setData.id,
          word: card.front,
          definition: card.back,
          language: detectLanguage(card.front)
        }));

        const { error: cardsError } = await supabase
          .from('flashcards')
          .insert(cardsToInsert);

        if (cardsError) throw cardsError;
      }

      onSuccess();
      onClose();
      window.location.reload();
    } catch (err) {
      console.error('Error saving cards:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-hidden">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-accent rounded-[2px] shadow-brutal w-full max-w-3xl relative flex flex-col max-h-[90vh]"
      >
        <div className="p-8 border-b border-white/10">
          <div className="absolute top-4 right-4">
            <button
              type="button"
              onClick={onClose}
              className="text-white hover:text-highlight"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {editingSet && (
            <div className="absolute top-16 right-4 flex gap-2">
              <button
                type="button"
                onClick={handleDownloadCSV}
                className="text-highlight hover:text-white transition-colors"
                title="Download CSV"
              >
                <Download className="w-6 h-6" />
              </button>
              <button
                type="button"
                onClick={handleDelete}
                disabled={deleting}
                className="button-3d bg-highlight text-black px-4 py-2 flex items-center gap-2"
              >
                <Trash2 className="w-4 h-4" />
                Delete
              </button>
            </div>
          )}

          <div className="mb-6">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-3xl font-bold bg-transparent text-white border-none focus:outline-none focus:ring-2 focus:ring-highlight rounded-[2px] w-full"
            />
          </div>

          <div className="mb-6">
            <label className="block text-white mb-2">Group Name (optional)</label>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="px-4 py-2 rounded-[2px] bg-white text-black focus:outline-none focus:ring-2 focus:ring-highlight w-full"
              placeholder="e.g. 'Spanish Verbs', 'Chapter 1', or leave blank"
            />
          </div>

          <div className="flex gap-4">
            <label className="flex items-center gap-2 text-white cursor-pointer">
              <input
                type="radio"
                checked={!isPublic}
                onChange={() => setIsPublic(false)}
                className="sr-only"
              />
              <div
                className={clsx(
                  "w-4 h-4 border-2 rounded-sm",
                  !isPublic ? "bg-highlight border-highlight" : "border-white"
                )}
              />
              Private
            </label>
            <label className="flex items-center gap-2 text-white cursor-pointer">
              <input
                type="radio"
                checked={isPublic}
                onChange={() => setIsPublic(true)}
                className="sr-only"
              />
              <div
                className={clsx(
                  "w-4 h-4 border-2 rounded-sm",
                  isPublic ? "bg-highlight border-highlight" : "border-white"
                )}
              />
              Public
            </label>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="flex-1 overflow-hidden flex flex-col">
          <div className="flex-1 overflow-y-auto">
            <div className="p-8">
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="text-white font-bold">Front of card</div>
                <div className="text-white font-bold">Back of card</div>
              </div>

              <div className="space-y-4 mb-8">
                {cards.map((card, index) => (
                  <div key={index} className="grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      value={card.front}
                      onChange={(e) => handleCardChange(index, 'front', e.target.value)}
                      className="px-4 py-2 rounded-[2px] bg-white text-black focus:outline-none focus:ring-2 focus:ring-highlight"
                      placeholder="Word or phrase"
                    />
                    <input
                      type="text"
                      value={card.back}
                      onChange={(e) => handleCardChange(index, 'back', e.target.value)}
                      className="px-4 py-2 rounded-[2px] bg-white text-black focus:outline-none focus:ring-2 focus:ring-highlight"
                      placeholder="Definition"
                    />
                  </div>
                ))}
              </div>

              <button
                type="button"
                onClick={handleAddCard}
                className="w-full py-3 border-2 border-dashed border-white/30 text-white/70 rounded-[2px] hover:border-white hover:text-white transition-colors mb-6 flex items-center justify-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Add Card
              </button>
            </div>
          </div>

          <div className="p-8 border-t border-white/10">
            {error && (
              <div className="bg-primary/20 border-2 border-primary text-white p-3 rounded-[2px] mb-6">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className={clsx(
                "button-3d w-full",
                loading && "opacity-70"
              )}
            >
              {loading ? 'Saving...' : editingSet ? 'Save Changes' : 'Create'}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}