import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from './AuthContext';

interface LivesContextType {
  globalLives: number;
  setGlobalLives: (lives: number) => void;
  resetLives: () => void;
}

const LivesContext = createContext<LivesContextType | undefined>(undefined);

export function LivesProvider({ children }: { children: React.ReactNode }) {
  const [globalLives, setGlobalLivesState] = useState<number>(20);

  // We'll use AuthContext to know which user is logged in
  const { user } = useAuth();

  // Helper to set globalLives in both local state and DB
  const updateLivesInDB = async (newLives: number) => {
    setGlobalLivesState(newLives);

    if (user?.id) {
      // Update in the 'profiles' table so that it persists
      const { error } = await supabase
        .from('profiles')
        .update({ lives: newLives })
        .eq('id', user.id);

      if (error) {
        console.error('Error updating lives in DB:', error);
      }
    }
  };

  // Exposed method for other components to set lives
  const setGlobalLives = (lives: number) => {
    updateLivesInDB(lives);
  };

  // Resets lives to 20 (or whatever your starting default is)
  const resetLives = () => {
    updateLivesInDB(20);
  };

  // Whenever we have a user, fetch the current lives from DB
  useEffect(() => {
    const loadLives = async () => {
      if (!user?.id) {
        // No user logged in, so let's default to 20
        setGlobalLivesState(20);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('lives')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (data && data.lives !== undefined) {
          setGlobalLivesState(data.lives);
        } else {
          // If for some reason the lives column is null,
          // set it to 20 in DB as a fallback
          updateLivesInDB(20);
        }
      } catch (err) {
        console.error('Error fetching lives from DB:', err);
        // fallback local state
        setGlobalLivesState(20);
      }
    };

    loadLives();
    // only run this when 'user' changes
  }, [user]);

  return (
    <LivesContext.Provider value={{ globalLives, setGlobalLives, resetLives }}>
      {children}
    </LivesContext.Provider>
  );
}

export function useLives() {
  const context = useContext(LivesContext);
  if (context === undefined) {
    throw new Error('useLives must be used within a LivesProvider');
  }
  return context;
}
