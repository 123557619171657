import React from 'react';
import { useLives } from '../contexts/LivesContext';

export default function GlobalLivesCounter() {
  const { globalLives } = useLives();

  return (
    <div
      className="
         
        top-0
        left-0
        w-full
        z-50
        flex 
        justify-center 
        items-center 
        bg-transparent  /* or a background if you like */
        py-2
      "
    >
      {/* Heart icon + text, smaller sizes */}
      <div className="flex items-end gap-2">
        <svg
          className="block"
          width="24"
          height="24"
          viewBox="0 0 47 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.5 12.75C23.5 9.89892 24.6326 7.16462 26.6486 5.1486C28.6646 3.13259 31.3989 2 34.25 2C41.775 2 45 9.525 45 15.4375C45 31.1003 30.1328 41.1515 25.1233 44.097C24.633 44.3801 24.0769 44.5291 23.5108 44.5291C22.9446 44.5291 22.3885 44.3801 21.8983 44.097C16.8673 41.1515 2 31.1003 2 15.4375C2 9.49275 5.225 2 12.75 2C14.1617 2 15.5596 2.27806 16.8638 2.8183C18.1681 3.35853 19.3532 4.15037 20.3514 5.1486C21.3496 6.14683 22.1415 7.3319 22.6817 8.63615C23.2219 9.9404 23.5 11.3383 23.5 12.75V12.75Z"
            fill="#FF1C60"
          />
          <path
            d="M23.5 12.75C23.5 9.89892 24.6326 7.16462 26.6486 5.1486C28.6646 3.13259 31.3989 2 34.25 2C41.775 2 45 9.525 45 15.4375C45 31.1003 30.1328 41.1515 25.1233 44.097C24.633 44.3801 24.0769 44.5291 23.5108 44.5291C22.9446 44.5291 22.3885 44.3801 21.8983 44.097C16.8673 41.1515 2 31.1003 2 15.4375C2 9.49275 5.225 2 12.75 2C14.1617 2 15.5596 2.27806 16.8638 2.8183C18.1681 3.35853 19.3532 4.15037 20.3514 5.1486C21.3496 6.14683 22.1415 7.3319 22.6817 8.63615C23.2219 9.9404 23.5 11.3383 23.5 12.75V12.75Z"
            stroke="#EBFF00"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="text-highlight font-luckiest text-2xl leading-none relative top-[4px]">
          ×
        </span>
        <span className="text-highlight font-luckiest text-2xl leading-none relative top-[4px]">
          {globalLives}
        </span>
      </div>
    </div>
  );
}
