import { franc } from 'franc';

// Map of franc language codes to ISO 639-1 codes
const languageCodeMap: Record<string, string> = {
  eng: 'en', // English
  spa: 'es', // Spanish
  fra: 'fr', // French
  deu: 'de', // German
  ita: 'it', // Italian
  por: 'pt', // Portuguese
  rus: 'ru', // Russian
  jpn: 'ja', // Japanese
  kor: 'ko', // Korean
  cmn: 'zh', // Chinese (Mandarin)
  // Add more mappings as needed
};

// Default to English if detection fails or confidence is low
const DEFAULT_LANGUAGE = 'en';

export function detectLanguage(text: string): string {
  try {
    // Detect language with franc
    const detectedLang = franc(text, { minLength: 1 });

    // If detection failed or confidence too low
    if (detectedLang === 'und') {
      return DEFAULT_LANGUAGE;
    }

    // Map the detected language code to ISO 639-1
    return languageCodeMap[detectedLang] || DEFAULT_LANGUAGE;
  } catch (error) {
    console.warn('Language detection failed:', error);
    return DEFAULT_LANGUAGE;
  }
}