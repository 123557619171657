import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';

// 1) Extend Profile interface to include "lives"
interface Profile {
  id: string;
  screenname: string | null;
  avatarUrl: string | null;
  loginStreak: number;
  friendInvites: number;
  totalPoints: number;
  timeSpent: number;
  // NEW: Keep track of how many lives the user has in the DB
  lives: number;
  created_at?: string;
  updated_at?: string;
}

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  signIn: (
    email: string,
    password: string,
    isSignUp?: boolean,
    referralCode?: string | null
  ) => Promise<{ error?: Error }>;
  signOut: () => Promise<void>;
  loading: boolean;
  error: string | null;
  updateAvatar: (url: string) => Promise<void>;
  updateScreenname: (screenname: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const mountedRef = useRef(true);
  const cleanupRef = useRef<(() => void) | null>(null);

  // 2) Updated createProfile to set "lives" to 20
  const createProfile = async (userId: string) => {
    try {
      // First check if profile exists
      const { data: existingProfile } = await supabase
        .from('profiles')
        .select('id')
        .eq('id', userId)
        .single();

      if (!existingProfile) {
        const { error: insertError } = await supabase.from('profiles').insert([
          {
            id: userId,
            login_streak: 0,
            last_login_date: new Date().toISOString(),
            lives: 20, // ensure new profile starts with 20 lives
          },
        ]);

        if (insertError) throw insertError;
      }
    } catch (err) {
      console.error('Error creating profile:', err);
    }
  };

  const updateLoginStreak = async (userId: string) => {
    try {
      const { error } = await supabase.rpc('update_login_streak', {
        user_id: userId,
      });

      if (error) throw error;
      // Fetch updated profile after streak update
      const { data: updatedProfile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (updatedProfile && mountedRef.current) {
        setProfile((prev) => ({
          ...prev!,
          loginStreak: updatedProfile.login_streak || 0,
        }));
      }
    } catch (err) {
      console.error('Error updating login streak:', err);
    }
  };

  // 3) fetchProfile now includes "lives" in the select
  const fetchProfile = async (userId: string) => {
    if (!userId) return;
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching profile:', error);
        return;
      }

      if (mountedRef.current && data) {
        setProfile({
          id: data.id,
          screenname: data.screenname,
          avatarUrl: data.avatar_url,
          loginStreak: data.login_streak || 0,
          friendInvites: data.friend_invites || 0,
          totalPoints: data.total_points || 0,
          timeSpent: data.time_spent || 0,
          // NEW: store the lives from DB
          lives: data.lives ?? 20,
        });
      }
    } catch (err) {
      console.error('Error fetching profile:', err);
    }
  };

  useEffect(() => {
    mountedRef.current = true;

    const initialize = async () => {
      try {
        setLoading(true);

        const {
          data: { session },
        } = await supabase.auth.getSession();

        if (session?.user) {
          setUser(session.user);
          await createProfile(session.user.id);
          await fetchProfile(session.user.id);
        } else {
          setUser(null);
          setProfile(null);
        }

        setLoading(false);
      } catch (err) {
        console.error('Auth initialization error:', err);
        setError('Failed to initialize auth');
        setLoading(false);
      }
    };

    initialize();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (!mountedRef.current) return;

      if (session?.user) {
        setUser(session.user);
        createProfile(session.user.id)
          .then(() => fetchProfile(session.user.id))
          .catch(console.error);
      } else {
        setUser(null);
        setProfile(null);
      }
    });

    cleanupRef.current = () => {
      subscription.unsubscribe();
    };

    return () => {
      mountedRef.current = false;
      if (cleanupRef.current) {
        cleanupRef.current();
      }
    };
  }, []);

  const signIn = async (
    email: string,
    password: string,
    isSignUp = false,
    referralCode?: string | null
  ) => {
    try {
      setError(null);

      if (isSignUp) {
        const { error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: { referralCode },
          },
        });
        if (error) throw error;
      } else {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;
      }

      return {};
    } catch (err) {
      const authError = err as AuthError;
      setError(authError.message);
      return { error: err as Error };
    }
  };

  const signOut = async () => {
    try {
      setError(null);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
    } catch (err) {
      const authError = err as AuthError;
      setError(authError.message);
      throw err;
    }
  };

  const updateScreenname = async (screenname: string) => {
    if (!user) throw new Error('No user logged in');

    const { error } = await supabase
      .from('profiles')
      .update({ screenname })
      .eq('id', user.id);

    if (error) throw error;

    // Update local profile state
    setProfile((prev) => (prev ? { ...prev, screenname } : null));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        signIn,
        signOut,
        loading,
        error,
        updateAvatar: async (url: string) => {
          if (!user?.id || !profile) return;

          // Optimistically update the UI
          setProfile((prev) =>
            prev
              ? {
                  ...prev,
                  avatarUrl: url,
                }
              : null
          );

          const { error } = await supabase
            .from('profiles')
            .update({ avatar_url: url })
            .eq('id', user.id);

          if (error) throw error;
        },
        updateScreenname,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
