import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { Loader2 } from 'lucide-react';
import clsx from 'clsx';

interface SignUpFormProps {
  onSuccess: () => void;
}

export default function SignUpForm({ onSuccess }: SignUpFormProps) {
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [error, setError] = useState('');
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [isSignUp, setIsSignUp] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');
    if (ref) {
      console.log('Referral code found:', ref);
      localStorage.setItem('referrer_id', ref);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted');
    setStatus('loading');
    setError('');
    const cleanEmail = email.trim().toLowerCase();
    console.log(
      'Email:',
      cleanEmail,
      'Password:',
      password,
      'IsSignUp:',
      isSignUp,
      'ReferralCode:',
      referralCode
    );

    try {
      const { error } = await signIn(
        cleanEmail,
        password,
        isSignUp,
        referralCode
      );
      console.log('Sign in response error:', error);

      if (error) throw error;

      setStatus('success');
      console.log('Sign in successful');
      setTimeout(onSuccess, 2000);
    } catch (err) {
      console.error('Sign in error:', err);
      setStatus('error');
      setError(err instanceof Error ? err.message : 'Failed to sign up');
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold text-white text-center mb-2">
          Welcome to Flash Cards
        </h2>
        <p className="text-white/80 text-center mb-8">
          {isSignUp
            ? 'Create an account to get started'
            : 'Welcome back! Please sign in'}
          {referralCode && (
            <span className="block mt-2 text-highlight">
              Joining via friend invite!
            </span>
          )}
        </p>

        {status === 'success' ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center"
          >
            <div className="bg-highlight/20 rounded-[2px] p-6 mb-4">
              <h3 className="text-xl font-bold text-white mb-2">
                {isSignUp ? 'Check your email!' : 'Welcome back!'}
              </h3>
              <p className="text-white/80">
                {isSignUp
                  ? "We've sent you a magic link to sign in. Click the link in your email to continue."
                  : "You'll be redirected to your dashboard in a moment."}
              </p>
            </div>
          </motion.div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white mb-2"
              >
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                autoCapitalize="none"
                autoCorrect="off"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={clsx(
                  'w-full px-4 py-3 bg-white rounded-[2px] text-black placeholder-gray-400',
                  'focus:outline-none focus:ring-2 focus:ring-highlight',
                  'transition-shadow duration-200',
                  status === 'error' && 'ring-2 ring-primary'
                )}
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-white mb-2"
              >
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoCapitalize="none"
                autoCorrect="off"
                autoComplete={isSignUp ? 'new-password' : 'current-password'}
                required
                minLength={6}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={clsx(
                  'w-full px-4 py-3 bg-white rounded-[2px] text-black placeholder-gray-400',
                  'focus:outline-none focus:ring-2 focus:ring-highlight',
                  'transition-shadow duration-200',
                  status === 'error' && 'ring-2 ring-primary'
                )}
                placeholder="Choose a password"
              />
            </div>

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-primary/20 border-2 border-primary text-white p-3 rounded-[2px]"
              >
                {error}
              </motion.div>
            )}

            <div className="text-center mb-4">
              <button
                type="button"
                onClick={() => {
                  console.log('Toggle sign up state:', !isSignUp);
                  setIsSignUp(!isSignUp);
                }}
                className="text-white hover:text-highlight"
              >
                {isSignUp
                  ? 'Already have an account? Log in'
                  : 'Need an account? Sign up'}
              </button>
            </div>

            <button
              type="submit"
              disabled={status === 'loading'}
              className={clsx(
                'button-3d w-full relative',
                'flex items-center justify-center',
                status === 'loading' && 'opacity-80'
              )}
            >
              {status === 'loading' ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin mr-2" />
                  {isSignUp ? 'Creating account...' : 'Logging in...'}
                </>
              ) : isSignUp ? (
                'Sign Up'
              ) : (
                'Log In'
              )}
            </button>

            <p className="text-sm text-white/60 text-center">
              By continuing, you agree to our Terms of Service and Privacy
              Policy.
            </p>
          </form>
        )}
      </motion.div>
    </div>
  );
}
