import React, { memo, useRef, useEffect, useState } from 'react';
import { Search } from 'lucide-react';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: () => void;
  placeholder?: string;
}

function SearchInput({ value, onChange, onSearch, placeholder = 'Search...' }: SearchInputProps) {
  // A local state to track what's displayed in the input, separate from parent state.
  // This is important for IME composition, so we don't prematurely update or override user input.
  const [localValue, setLocalValue] = useState(value);
  const [isComposing, setIsComposing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Whenever the parent "value" changes (e.g. from outside), sync it to localValue
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Focus on the input if you like
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the localValue
    setLocalValue(e.target.value);

    // If we're NOT in the middle of an IME composition, also notify the parent
    if (!isComposing) {
      onChange(e.target.value);
    }
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = (e: React.CompositionEvent<HTMLInputElement>) => {
    // Composition is done; the IME has finalized the text
    setIsComposing(false);
    setLocalValue(e.target.value);
    // Now notify the parent of the final text
    onChange(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // If we press Enter *and* we are NOT composing text, trigger the search
    if (e.key === 'Enter' && !isComposing) {
      e.preventDefault();
      onSearch();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-2">
      <div className="relative flex-1">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-black/50" />
        <input
          ref={inputRef}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          placeholder={placeholder}
          className="w-full pl-10 pr-4 py-2 rounded-[2px] bg-white text-black placeholder-black/50 focus:outline-none focus:ring-2 focus:ring-highlight transition-shadow"
          value={localValue}
          onChange={handleChange}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button
        type="submit"
        className="button-3d py-2 w-[120px] flex items-center justify-center gap-0"
      >
        <Search className="w-5 h-5" />
        Search
      </button>
    </form>
  );
}

export default memo(SearchInput);
