import { useCallback, useRef, useState } from 'react';
import Header from './Header';
import { RubiksCube } from './RubiksCube';
import { RetroGrid } from './RetroGrid';
import { WordGame } from './WordGame';
import { useLives } from '../contexts/LivesContext';
import { ArrowLeft, RotateCcw } from 'lucide-react';

interface CubeyGameProps {
  onExit: () => void;
}

export default function CubeyGame({ onExit }: CubeyGameProps) {
  const [score, setScore] = useState(0);
  const { globalLives, setGlobalLives } = useLives();
  const cubeRef = useRef<{ handleShuffle: () => void; handleSolve: () => void }>(null);

  const [gameOver, setGameOver] = useState(false);
  const totalWords = parseInt(localStorage.getItem('totalWords') || '0', 10);

  const handleWordComplete = useCallback((newScore: number, isCorrect: boolean) => {
    setScore(newScore);
    if (isCorrect) {
      // Correct answer - solve cube
      cubeRef.current?.handleSolve();
    } else {
      // Wrong answer - decrement lives
      setGlobalLives(prev => {
        const newLives = prev - 1;
        if (newLives <= 0) {
          setGameOver(true);
        }
        return newLives;
      });
    }
  }, [setGlobalLives]);

  const handleGameStart = useCallback(() => {
    cubeRef.current?.handleShuffle();
  }, []);

  const handlePlayAgain = useCallback(() => {
    setScore(0);
    setGameOver(false);
    // Solve then shuffle again
    cubeRef.current?.handleSolve();
    cubeRef.current?.handleShuffle();
  }, []);

  return (
    <div className="min-h-screen bg-primary flex flex-col">
      <RetroGrid />
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-8">
        <Header showBackButton onBack={onExit} score={score} />

        {/*
          Use items-start instead of items-center
          Use pt-20 (5rem) or bracket notation e.g. pt-[72px]
        */}
        <div className="flex-1 flex flex-col items-start justify-center pt-20">
          <div className="mx-auto">
            <RubiksCube ref={cubeRef} />
            <div className="mt-8">
              <WordGame 
                onWordComplete={handleWordComplete} 
                onGameStart={handleGameStart} 
                totalWords={totalWords}
              />
            </div>
          </div>
        </div>

        {/* Game Over Modal */}
        {(gameOver || globalLives <= 0) && (
          <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
            <div className="bg-blue-800 p-8 rounded-[2px] text-center text-white">
              <h2 className="text-4xl font-bold mb-4">Game Over!</h2>
              <p className="text-2xl mb-2">Final Score: {score}</p>
              <p className="text-lg text-white/80 mb-6">
                {globalLives <= 0 ? "Out of lives!" : "Game complete!"}
              </p>
              <div className="space-x-4">
                <button
                  onClick={handlePlayAgain}
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <RotateCcw className="w-5 h-5" />
                  Play Again
                </button>
                <button
                  onClick={onExit}
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <ArrowLeft className="w-5 h-5" />
                  Exit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
