// SetsPage.tsx

import { useState, useEffect, useRef } from 'react';
import { Plus, ArrowLeft } from 'lucide-react';
import { Logo } from './Logo';
import Header from './Header';
import FileUpload from './FileUpload';
import CreateCardsModal from './CreateCardsModal';
import ReferralModal from './ReferralModal';
import LeaderboardModal from './LeaderboardModal';
import AuthModal from './AuthModal';
import SearchInput from './SearchInput';
import { FlashCard, FlashCardSet } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { removeDuplicateSets, getUniqueSetName } from '../utils/setUtils';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';

interface SetsPageProps {
  sets: FlashCardSet[];
  onSetSelect: (setId: string) => void;
  onCardsUpload: (cards: FlashCard[], fileName: string) => void;
  onStartQuiz: () => void;
}

function normalizeForSearch(str: string): string {
  // NFKC normalization + lowercase supports Japanese/Unicode searching
  return str.normalize('NFKC').toLowerCase();
}

export default function SetsPage({
  sets,
  onSetSelect,
  onCardsUpload,
  onStartQuiz,
}: SetsPageProps) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showLeaderboardModal, setShowLeaderboardModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [editingSet, setEditingSet] = useState<FlashCardSet | null>(null);

  // PUBLIC/PRIVATE
  const [publicSets, setPublicSets] = useState<FlashCardSet[]>([]);
  const [showingPublicSets, setShowingPublicSets] = useState(false);

  // SEARCH
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedSets, setDisplayedSets] = useState<FlashCardSet[]>([]);
  const [loading, setLoading] = useState(false);
  const [importing, setImporting] = useState<string | null>(null);

  // AUTH / STATS
  const { user, profile } = useAuth();
  const [dailyPoints, setDailyPoints] = useState(0);
  const interactionAudioRef = useRef<HTMLAudioElement | null>(null);
  const [streak, setStreak] = useState<number | null>(null);
  const [isFirstActivity, setIsFirstActivity] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showDailyPoints, setShowDailyPoints] = useState(false);

  // COLLAPSE / GROUP ORDER
  const [collapsedGroups, setCollapsedGroups] = useState<Record<string, boolean>>({});
  const [groupOrder, setGroupOrder] = useState<string[]>([]);

  /**
   * ---------------------------
   * SOUND SETUP
   * ---------------------------
   */
  useEffect(() => {
    interactionAudioRef.current = new Audio('/Interaction_Sound.mp3');
    interactionAudioRef.current.load();

    return () => {
      if (interactionAudioRef.current) {
        interactionAudioRef.current.pause();
        interactionAudioRef.current = null;
      }
    };
  }, []);

  function playInteractionSound() {
    if (interactionAudioRef.current) {
      interactionAudioRef.current.currentTime = 0;
      interactionAudioRef.current.play().catch(console.error);
    }
  }

  /**
   * ---------------------------
   * FETCH DAILY POINTS
   * ---------------------------
   */
  useEffect(() => {
    if (!user) return;

    const fetchDailyPoints = async () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const { data, error } = await supabase
        .from('game_scores')
        .select('score')
        .eq('user_id', user.id)
        .gte('created_at', today.toISOString());

      if (!error && data) {
        const total = data.reduce((sum, score) => sum + score.score, 0);
        setDailyPoints(total);
      }
    };

    fetchDailyPoints();
  }, [user]);

  /**
   * ---------------------------
   * COLLAPSED GROUPS INIT
   * ---------------------------
   */
  useEffect(() => {
    if (!user) return;

    let storedGroups: Record<string, boolean> = {};
    const savedState = localStorage.getItem(`collapsedGroups_${user.id}`);

    if (savedState) {
      try {
        storedGroups = JSON.parse(savedState);
      } catch (err) {
        console.error('Failed to parse collapsedGroups from localStorage', err);
      }
    }

    // Get all group names from user sets
    const groupNames = new Set(sets.map((s) => s.groupName?.trim() || 'Ungrouped'));

    // default new groups to collapsed = true
    groupNames.forEach((gn) => {
      if (storedGroups[gn] === undefined) {
        storedGroups[gn] = true;
      }
    });

    setCollapsedGroups(storedGroups);
  }, [user, sets]);

  /**
   * ---------------------------
   * DEFAULT DISPLAYED SETS
   * ---------------------------
   */
  useEffect(() => {
    if (!showingPublicSets) {
      setDisplayedSets(sets);
    }
  }, [sets, showingPublicSets]);

  /**
   * ---------------------------
   * LOAD PUBLIC SETS
   * ---------------------------
   */
  useEffect(() => {
    if (showingPublicSets) {
      setDisplayedSets(publicSets);
    }
  }, [showingPublicSets, publicSets]);

  /**
   * ---------------------------
   * FETCH PUBLIC SETS
   * ---------------------------
   */
  useEffect(() => {
    const fetchPublicSets = async () => {
      if (!showingPublicSets) return;

      try {
        setLoading(true);

        const { data: setsData, error: setsError } = await supabase
          .from('flashcard_sets')
          .select(`
            id,
            name,
            created_at,
            is_public,
            group_name,
            flashcards (*)
          `)
          .eq('is_public', true)
          .order('created_at', { ascending: false });

        if (setsError) throw setsError;

        const transformedSets: FlashCardSet[] = setsData.map((set: any) => ({
          id: set.id,
          name: set.name,
          createdAt: new Date(set.created_at),
          totalCards: (set.flashcards as any[]).length,
          masteredCards: 0,
          groupName: set.group_name || null,
        }));

        setPublicSets(transformedSets);
        setDisplayedSets(transformedSets);
      } catch (err) {
        console.error('Error fetching public sets:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPublicSets();
  }, [showingPublicSets]);

  /**
   * ---------------------------
   * LIVE SEARCH - PUBLIC / PRIVATE
   * ---------------------------
   */
  useEffect(() => {
    if (showingPublicSets) {
      // Filter public sets
      const filterSets = async () => {
        try {
          setLoading(true);

          const { data: setsData, error: setsError } = await supabase
            .from('flashcard_sets')
            .select(`
              id,
              name,
              created_at,
              is_public,
              group_name,
              flashcards (word, definition)
            `)
            .eq('is_public', true)
            .order('created_at', { ascending: false });

          if (setsError) throw setsError;

          const searchNorm = normalizeForSearch(searchQuery);

          const filteredSets = setsData.filter((set: any) => {
            if (normalizeForSearch(set.name).includes(searchNorm)) return true;

            const cards = set.flashcards as { word: string; definition: string }[];
            return cards.some(
              (card) =>
                normalizeForSearch(card.word).includes(searchNorm) ||
                normalizeForSearch(card.definition).includes(searchNorm)
            );
          });

          const transformedSets: FlashCardSet[] = filteredSets.map((set: any) => ({
            id: set.id,
            name: set.name,
            createdAt: new Date(set.created_at),
            totalCards: (set.flashcards as any[]).length,
            masteredCards: 0,
            groupName: set.group_name || null,
          }));

          setDisplayedSets(transformedSets);
        } catch (err) {
          console.error('Error filtering sets:', err);
        } finally {
          setLoading(false);
        }
      };
      filterSets();
    } else {
      // Filter private sets in memory
      const searchNorm = normalizeForSearch(searchQuery);
      const filtered = sets.filter((set) =>
        normalizeForSearch(set.name).includes(searchNorm)
      );
      setDisplayedSets(filtered);
    }
  }, [searchQuery, showingPublicSets, sets]);

  const handleSearch = async () => {
    // No-op: we rely on the above effect for live search
  };

  /**
   * ---------------------------
   * HANDLE AUTH
   * ---------------------------
   */
  const handleShowAuthModal = () => {
    setShowAuthModal(true);
  };

  const handleSetSelect = async (setId: string) => {
    if (!user) return;

    localStorage.setItem('selectedFlashcardSetId', setId);
    const { error: streakError } = await supabase.rpc('handle_user_activity', {
      user_id: user.id,
    });
    if (streakError) {
      console.error('Error updating activity streak:', streakError);
    }

    onSetSelect(setId);
  };

  /**
   * ---------------------------
   * IMPORT PUBLIC SET
   * ---------------------------
   */
  const handleImportSet = async (setId: string) => {
    if (!user) {
      handleShowAuthModal();
      return;
    }
    try {
      setImporting(setId);
      setError(null);

      const { data: originalSet, error: fetchError } = await supabase
        .from('flashcard_sets')
        .select(`
          id,
          name,
          is_public,
          group_name,
          flashcards (*)
        `)
        .eq('id', setId)
        .single();

      if (fetchError) {
        console.error('Error fetching original set:', fetchError);
        setError('Failed to fetch flash card set');
        return;
      }

      if (!originalSet || !originalSet.flashcards) {
        throw new Error('Flash card set not found or contains no cards');
      }

      const cards = originalSet.flashcards.map((card: any) => ({
        word: card.word,
        definition: card.definition,
      }));

      if (cards.length === 0) {
        throw new Error('No cards found in the set');
      }

      const { data: newSet, error: newSetError } = await supabase
        .from('flashcard_sets')
        .insert({
          name: originalSet.name,
          created_by: user.id,
          is_public: true,
          group_name: originalSet.group_name || null,
        })
        .select()
        .single();

      if (newSetError) {
        console.error('Error creating new set:', newSetError);
        throw new Error('Failed to create new flash card set');
      }

      const { error: cardsError } = await supabase
        .from('flashcards')
        .insert(
          cards.map((card) => ({
            set_id: newSet.id,
            word: card.word,
            definition: card.definition,
          }))
        );

      if (cardsError) {
        await supabase.from('flashcard_sets').delete().eq('id', newSet.id);
        console.error('Error inserting cards:', cardsError);
        throw new Error('Failed to add cards to the set');
      }

      window.location.reload();
    } catch (err) {
      console.error('Error importing set:', err);
      const message = err instanceof Error ? err.message : 'Failed to import set';
      alert(message);
    } finally {
      setImporting(null);
    }
  };

  /**
   * ---------------------------
   * STREAK LOGIC
   * ---------------------------
   */
  useEffect(() => {
    const handleActivity = async () => {
      if (!user) return;

      try {
        const { data: userData, error } = await supabase
          .from('profiles')
          .select('activity_streak, last_updated')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Failed to fetch user streak:', error);
          setError('Failed to update streak');
          return;
        }

        const today = new Date().toISOString().split('T')[0];
        const lastUpdated = userData?.last_updated;

        let newStreak = userData?.activity_streak || 0;
        if (lastUpdated !== today) {
          newStreak += 1;

          const { error: updateError } = await supabase
            .from('profiles')
            .update({ activity_streak: newStreak, last_updated: today })
            .eq('id', user.id);
          if (updateError) throw updateError;

          setStreak(newStreak);
          setIsFirstActivity(true);
        } else {
          setStreak(newStreak);
          setIsFirstActivity(false);
        }
      } catch (err) {
        console.error('Unexpected error while updating streak:', err);
        setError('An error occurred while updating your streak.');
      }
    };

    handleActivity();
  }, [user]);

  /**
   * ---------------------------
   * GROUP UTILITIES
   * ---------------------------
   */
  function groupSetsByGroupName(flashCardSets: FlashCardSet[]) {
    return flashCardSets.reduce((acc, set) => {
      const group = set.groupName?.trim() || 'Ungrouped';
      if (!acc[group]) acc[group] = [];
      acc[group].push(set);
      return acc;
    }, {} as Record<string, FlashCardSet[]>);
  }

  function toggleGroup(groupName: string) {
    setCollapsedGroups((prev) => {
      const newState = { ...prev, [groupName]: !prev[groupName] };
      if (user) {
        localStorage.setItem(`collapsedGroups_${user.id}`, JSON.stringify(newState));
      }
      return newState;
    });
  }

  const toggleButtonLabel = showingPublicSets ? 'Your Flash Cards' : 'Public Flash Cards';
  function handleTogglePublicPrivate() {
    setShowingPublicSets(!showingPublicSets);
  }

  // Reordering helper
  function reorder(list: string[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;
    const newOrder = reorder(groupOrder, result.source.index, result.destination.index);
    setGroupOrder(newOrder);
    if (user) {
      localStorage.setItem(`groupOrder_${user.id}`, JSON.stringify(newOrder));
    }
  }

  /**
   * ------------------------------------
   * LoggedInView
   * ------------------------------------
   */
  const LoggedInView = () => {
    // Group all private sets in memory
    const groupedPrivateSets = groupSetsByGroupName(displayedSets);
    const allGroups = Object.keys(groupedPrivateSets);

    // ---------------------------
    // 1) LOAD groupOrder from storage ONCE if it's empty
    // ---------------------------
    useEffect(() => {
      if (!user) return;
      if (allGroups.length === 0) return;

      // If groupOrder is empty, try loading from localStorage
      if (groupOrder.length === 0) {
        const savedOrder = localStorage.getItem(`groupOrder_${user.id}`);
        if (savedOrder) {
          try {
            setGroupOrder(JSON.parse(savedOrder));
          } catch (err) {
            console.error('Error parsing saved group order:', err);
          }
        }
      }
    }, [user, allGroups]);

    // ---------------------------
    // 2) MERGE any new groups that aren't in groupOrder
    // ---------------------------
    useEffect(() => {
      if (allGroups.length === 0) return;

      setGroupOrder((prevOrder) => {
        // Filter out groups we already have
        const missing = allGroups.filter((g) => !prevOrder.includes(g));
        if (missing.length === 0) {
          return prevOrder; // No changes => won't re-render infinitely
        }
        const newOrder = [...prevOrder, ...missing];
        // Store updated order
        if (user) {
          localStorage.setItem(`groupOrder_${user.id}`, JSON.stringify(newOrder));
        }
        return newOrder;
      });
    }, [allGroups]);

    return (
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="flex flex-col items-center gap-12">
          <Header />

          <div className="text-center space-y-4">
            {error && <div className="text-red-500">{error}</div>}
            <div className="text-highlight font-luckiest text-5xl">
              {streak ? `${streak} Day Streak! 🔥` : 'Welcome Back!'}
            </div>
            <div
              className="text-highlight font-luckiest text-4xl cursor-pointer hover:scale-105 transition-transform"
              onClick={() => {
                playInteractionSound();
                setShowDailyPoints(!showDailyPoints);
              }}
            >
              {showDailyPoints
                ? `Daily Points: ${dailyPoints}`
                : `Total Points: ${profile?.totalPoints || 0}`}
            </div>
          </div>

          <div className="flex items-center gap-8 font-luckiest mt-6">
            <button
              onClick={() => {
                playInteractionSound();
                setShowReferralModal(true);
              }}
              className="hover:scale-110 transition-transform flex items-center gap-2"
            >
              <span className="text-xl md:text-2xl">FRIENDS</span>
              <span className="text-3xl md:text-4xl">🤩</span>
            </button>
            <button
              onClick={() => {
                playInteractionSound();
                setShowLeaderboardModal(true);
              }}
              className="hover:scale-110 transition-transform flex items-center gap-2"
            >
              <span className="text-3xl md:text-4xl">🏆</span>
              <span className="text-xl md:text-2xl">LEADERBOARD</span>
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center mb-16 space-y-4">
          <div className="flex justify-center w-full max-w-4xl space-x-4">
            <button
              onClick={handleTogglePublicPrivate}
              className={`button-3d py-2 px-2 md:px-8 w-1/3 text-xs md:text-base ${
                showingPublicSets ? 'bg-accent text-white' : ''
              }`}
            >
              {toggleButtonLabel}
            </button>

            <label
              htmlFor="file-upload"
              className="button-3d py-2 px-2 md:px-8 w-1/3 text-center text-xs md:text-base"
            >
              Upload CSV
            </label>

            <button
              onClick={() => {
                setEditingSet(null);
                setShowCreateModal(true);
              }}
              className="button-3d py-2 px-2 md:px-8 w-1/3 flex items-center justify-center gap-2 text-xs md:text-base"
            >
              <Plus className="w-5 h-5" />
              Create New Cards
            </button>
          </div>
        </div>

        {showingPublicSets ? (
          // ===================== PUBLIC SETS =====================
          <div className="mb-12">
            <div className="flex justify-between items-center mb-8">
              <button
                onClick={() => setShowingPublicSets(false)}
                className="button-3d flex items-center gap-2"
              >
                <ArrowLeft className="w-5 h-5" />
                Exit
              </button>
              <h2 className="text-4xl font-bold text-white">
                Public Flash Card Sets
              </h2>
            </div>

            <div className="mb-6">
              <SearchInput
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
                onSearch={handleSearch}
                placeholder="Search sets by title or content..."
              />
            </div>

            {loading ? (
              <div className="text-white text-center">Loading public sets...</div>
            ) : (
              (() => {
                const groupedPublicSets = groupSetsByGroupName(displayedSets);
                const groupNames = Object.keys(groupedPublicSets);

                if (groupNames.length === 0) {
                  return (
                    <p className="text-white text-center">
                      No matching sets found.
                    </p>
                  );
                }

                return groupNames.map((groupName) => {
                  const isCollapsed = collapsedGroups[groupName] ?? true;
                  const setsInGroup = groupedPublicSets[groupName];

                  return (
                    <div key={groupName} className="mb-6">
                      {/* Notice: no onMouseDown here => no infinite update when toggling */}
                      <div
                        className="cursor-pointer bg-[#FF1C60] text-white px-4 py-2 font-bold text-xl rounded-t-[2px] flex justify-between items-center"
                        onClick={() => toggleGroup(groupName)}
                      >
                        <span>
                          {groupName === 'Ungrouped'
                            ? 'Ungrouped Sets'
                            : groupName}
                        </span>
                        <span className="ml-2 text-base">
                          {isCollapsed ? '▶' : '▼'}
                        </span>
                      </div>

                      {!isCollapsed && (
                        <div className="space-y-4 bg-accent/20 rounded-b-[2px] p-4">
                          {setsInGroup.map((set) => (
                            <div
                              key={set.id}
                              className="card-set block w-full text-left"
                            >
                              <h3 className="text-2xl font-bold mb-2">
                                {set.name}
                              </h3>
                              <div className="flex justify-between items-center mt-2">
                                <span className="text-white/60">
                                  {set.totalCards} cards
                                </span>
                                <button
                                  onClick={() => handleImportSet(set.id)}
                                  className="button-3d py-2 px-4"
                                  disabled={importing === set.id}
                                >
                                  {importing === set.id
                                    ? 'Importing...'
                                    : 'Import'}
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                });
              })()
            )}
          </div>
        ) : (
          // ===================== PRIVATE SETS =====================
          <div className="mb-12">
            <h2 className="text-4xl font-bold text-white mb-8">
              Your Flash Card Sets
            </h2>

            <div className="mb-6">
              <SearchInput
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
                onSearch={handleSearch}
                placeholder="Search your sets by title..."
              />
            </div>

            {(() => {
              const groupedPrivateSets = groupSetsByGroupName(displayedSets);
              const groupNames = Object.keys(groupedPrivateSets);

              if (groupNames.length === 0) {
                return (
                  <p className="text-white text-center">
                    No matching sets found.
                  </p>
                );
              }

              return (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="groups-droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {groupOrder.map((groupName, index) => {
                          if (!groupedPrivateSets[groupName]) return null;

                          const setsInGroup = groupedPrivateSets[groupName];
                          const isCollapsed = collapsedGroups[groupName] ?? true;

                          return (
                            <Draggable
                              key={groupName}
                              draggableId={groupName}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <div
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    userSelect: 'none',
                                    marginBottom: '1rem',
                                  }}
                                >
                                  {/* On private sets, we keep onMouseDown to let you drag the group */}
                                  <div
                                    {...draggableProvided.dragHandleProps}
                                    className="cursor-pointer bg-[#FF1C60] text-white px-4 py-2 font-bold text-xl rounded-t-[2px] flex justify-between items-center"
                                    onMouseDown={(e) => {
                                      e.preventDefault(); // prevents focusing during drag
                                    }}
                                    onClick={() => toggleGroup(groupName)}
                                  >
                                    <span>
                                      {groupName === 'Ungrouped'
                                        ? 'Ungrouped Sets'
                                        : groupName}
                                    </span>
                                    <span className="ml-2 text-base">
                                      {isCollapsed ? '▶' : '▼'}
                                    </span>
                                  </div>

                                  {!isCollapsed && (
                                    <div className="space-y-4 bg-accent/20 rounded-b-[2px] p-4">
                                      {setsInGroup.map((set) => (
                                        <div
                                          key={set.id}
                                          className="p-6 rounded-[2px] w-full text-left card-set"
                                        >
                                          <div className="flex justify-between items-start mb-2">
                                            <h3 className="text-2xl font-bold">
                                              {set.name}
                                            </h3>
                                            <button
                                              onClick={() => {
                                                setEditingSet(set);
                                                setShowCreateModal(true);
                                              }}
                                              className="button-3d py-1 px-3 text-sm"
                                            >
                                              Edit
                                            </button>
                                          </div>
                                          <button
                                            onClick={() =>
                                              handleSetSelect(set.id)
                                            }
                                            className="flex justify-end items-center mt-2 w-full"
                                          >
                                            <span className="text-xl">
                                              {set.totalCards} cards →
                                            </span>
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              );
            })()}
          </div>
        )}

        {!showingPublicSets && <FileUpload onCardsUpload={onCardsUpload} />}
      </div>
    );
  };

  /**
   * ------------------------------------
   * PreLoginView
   * ------------------------------------
   */
  const PreLoginView = () => (
    <div className="min-h-screen bg-primary text-white">
      <div className="w-full flex justify-between items-center p-4">
        <Logo />
        <button onClick={handleShowAuthModal} className="button-3d">
          Sign Up / Login
        </button>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row items-start justify-between gap-12">
          {/*  Big headings, Tetris-like block, bullet points, etc. */}
          <div className="lg:w-1/2 space-y-8 lg:space-y-16 mb-8 lg:mb-0">
            <div className="space-y-8 lg:space-y-12">
              <h1 className="text-[4rem] lg:text-[5.5rem] font-bold leading-[0.9]">
                Play Time
                <br />
                equals
                <br />
                Learn Time
              </h1>

              <p className="text-lg font-bold">
                Learn effortlessly through games.
                <br />
                Make your own flash card sets, or use pre-made study plans.
              </p>
            </div>

            <div>
              <button onClick={handleShowAuthModal} className="button-3d">
                Sign Up For Free
              </button>
            </div>

            <div className="hidden lg:block">
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Faster Learning:</strong>
                      <p>
                        Master skills up to 50% faster with immersive, interactive
                        games.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">
                        Increase Motivation:
                      </strong>
                      <p>
                        Learners spend 34% more time on tasks compared to
                        traditional methods.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Increased Focus:</strong>
                      <p>
                        Captures and sustains attention, especially for learners
                        with ADHD.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <span className="text-highlight">•</span>
                    <div>
                      <strong className="text-highlight">Skill Development:</strong>
                      <p>
                        Helps learners with autism build social and communication
                        skills.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tetris-like example */}
          <div className="lg:w-1/2 w-full">
            <div className="relative max-w-md mx-auto">
              <div className="absolute -top-8 right-0 text-right">
                <p className="text-white/80">Score</p>
                <p className="text-highlight text-4xl font-bold">10</p>
              </div>

              <div className="bg-accent rounded-[2px] p-4 shadow-brutal aspect-[10/16] w-full">
                <div className="w-full h-full relative">
                  <div className="absolute top-[10%] left-[20%] grid grid-cols-3 gap-0.5">
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute top-[5%] right-[30%] grid grid-cols-1 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute top-[25%] left-[40%] grid grid-cols-2 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                  </div>

                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 grid grid-cols-3 gap-0.5">
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6 bg-white shadow-inner"></div>
                    <div className="w-6 h-6"></div>
                    <div className="w-6 h-6"></div>
                  </div>
                </div>
              </div>

              <div className="bg-accent mt-4 p-4 rounded-[2px] shadow-brutal">
                <h3 className="text-4xl mb-4 font-bold">opulence</h3>
                <div className="grid grid-cols-2 gap-4">
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    ruling family
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    astonished
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    wealth
                  </button>
                  <button className="bg-highlight text-black p-2 rounded-[2px] shadow-brutal font-extrabold">
                    said no
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* For mobile */}
        <div className="mt-12 lg:hidden">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Faster Learning:</strong>
                  <p>
                    Master skills up to 50% faster with immersive, interactive games.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Increase Motivation:</strong>
                  <p>
                    Learners spend 34% more time on tasks compared to traditional
                    methods.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Increased Focus:</strong>
                  <p>
                    Captures and sustains attention, especially for learners with
                    ADHD.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <span className="text-highlight">•</span>
                <div>
                  <strong className="text-highlight">Skill Development:</strong>
                  <p>
                    Helps learners with autism build social and communication skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  /**
   * ---------------------------
   * FINAL RETURN
   * ---------------------------
   */
  return (
    <>
      {user ? <LoggedInView /> : <PreLoginView />}

      <CreateCardsModal
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setEditingSet(null);
        }}
        onSuccess={() => window.location.reload()}
        editingSet={editingSet}
      />

      <ReferralModal
        isOpen={showReferralModal}
        onClose={() => setShowReferralModal(false)}
      />

      <LeaderboardModal
        isOpen={showLeaderboardModal}
        onClose={() => setShowLeaderboardModal(false)}
      />

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  );
}
