import React, { useState, useEffect, useCallback, useRef } from "react";
import { KeyboardButton } from "./KeyboardButton";
import { LongKeyboardButton } from "./LongKeyboardButton";
import { FlashCard, FlashCardSet } from "../types";
import { useAuth } from '../contexts/AuthContext';
import { useLives } from '../contexts/LivesContext';
import { supabase } from '../lib/supabase';
import Header from './Header';
import { ArrowLeft, RotateCcw } from 'lucide-react';

interface ButtonzGameProps {
  cards: FlashCard[];
  currentSet: FlashCardSet | null;
  onExit: () => void;
}

export default function ButtonzGame({ cards, currentSet, onExit }: ButtonzGameProps) {
  const { user } = useAuth();
  const { globalLives, setGlobalLives } = useLives();

  // Game / Audio State
  const [gameStartTime, setGameStartTime] = useState(() => Date.now());
  const [isGameActive, setIsGameActive] = useState(true);
  const [timeLeft, setTimeLeft] = useState(90);

  const successAudioRef = useRef<HTMLAudioElement | null>(null);
  const mistakeAudioRef = useRef<HTMLAudioElement | null>(null);

  // Card / Display State
  const [allCards, setAllCards] = useState<FlashCard[]>([]);
  const [currentWords, setCurrentWords] = useState<string[]>([]);
  const [currentDefinitions, setCurrentDefinitions] = useState<string[]>([]);

  // Selections (we track them, but do NOT use a `useEffect` to check)
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [selectedDefinition, setSelectedDefinition] = useState<string | null>(null);

  // Score / Error
  const [score, setScore] = useState(0);
  const [error, setError] = useState<string | null>(null);

  // Fade-out effect states
  const [matchedWord, setMatchedWord] = useState<string | null>(null);
  const [matchedDefinition, setMatchedDefinition] = useState<string | null>(null);
  const [tempMatchedPairs, setTempMatchedPairs] = useState<Set<string>>(new Set());

  const BUTTONS_PER_SIDE = 6;

  // Shuffle helper
  function shuffleArray<T>(array: T[]): T[] {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  // Audio Setup
  useEffect(() => {
    successAudioRef.current = new Audio('/Success_Sound.mp3');
    successAudioRef.current.load();
    mistakeAudioRef.current = new Audio('/Mistake_Sound.mp3');
    mistakeAudioRef.current.load();

    return () => {
      if (successAudioRef.current) {
        successAudioRef.current.pause();
        successAudioRef.current = null;
      }
      if (mistakeAudioRef.current) {
        mistakeAudioRef.current.pause();
        mistakeAudioRef.current = null;
      }
    };
  }, []);

  // Load deck and show initial 6
  useEffect(() => {
    if (!currentSet) return;

    const setCards = cards.filter(card => card.setId === currentSet.id);
    if (setCards.length === 0) {
      setError('No cards found in this set');
      return;
    }

    setAllCards(setCards);
    showNewCards(setCards);
  }, [cards, currentSet]);

  // Timer
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isGameActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            setIsGameActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isGameActive, timeLeft]);

  // showNewCards: picks 6 random cards
  const showNewCards = useCallback((deck: FlashCard[]) => {
    if (deck.length === 0) return;
    const shuffled = shuffleArray(deck);
    const subset = shuffled.slice(0, BUTTONS_PER_SIDE);

    setCurrentWords(subset.map(card => card.word));
    const definitions = subset.map(card => card.definition);
    setCurrentDefinitions(shuffleArray(definitions));
  }, []);

  // This function is called after we know "correct" or "wrong"
  const handleMatch = useCallback(
    async (isCorrect: boolean) => {
      if (isCorrect) {
        // +1 point
        if (successAudioRef.current) {
          successAudioRef.current.currentTime = 0;
          await successAudioRef.current.play();
        }
        setScore(prev => prev + 1);

        setMatchedWord(selectedWord);
        setMatchedDefinition(selectedDefinition);

        // Mark the matched word so it has fade-out effect
        if (selectedWord) {
          setTempMatchedPairs(new Set([selectedWord]));
        }

        // Show new 6 after short delay
        setTimeout(() => {
          showNewCards(allCards);
          setMatchedWord(null);
          setMatchedDefinition(null);
          setTempMatchedPairs(new Set());
        }, 300);
      } else {
        // -1 point
        if (mistakeAudioRef.current) {
          mistakeAudioRef.current.currentTime = 0;
          await mistakeAudioRef.current.play();
        }
        setScore(prev => prev - 1);

        // Decrement lives, but do NOT go below 0
        setGlobalLives(prev => (prev > 0 ? prev - 1 : 0));
      }

      // Save to DB if user is logged in
      if (user) {
        try {
          const duration = Math.floor((Date.now() - gameStartTime) / 1000);

          const { error: insertError } = await supabase.from('game_scores').insert({
            user_id: user.id,
            game_type: 'buttonz',
            score: isCorrect ? 1 : -1,
            duration,
          });
          if (insertError) throw insertError;

          const { data: profile, error: fetchError } = await supabase
            .from('profiles')
            .select('time_spent')
            .eq('id', user.id)
            .single();

          if (fetchError) throw fetchError;

          const newTimeSpent = (profile?.time_spent || 0) + duration;
          const { error: updateError } = await supabase
            .from('profiles')
            .update({ time_spent: newTimeSpent })
            .eq('id', user.id);
          if (updateError) throw updateError;

        } catch (err) {
          console.error('Error saving score or updating time spent:', err);
        }
      }

      // Reset local selections
      setSelectedWord(null);
      setSelectedDefinition(null);
    },
    [
      allCards,
      gameStartTime,
      showNewCards,
      selectedWord,
      selectedDefinition,
      user,
      setGlobalLives
    ]
  );

  /**
   * Instead of using an effect to check "selectedWord && selectedDefinition",
   * we do the matching check immediately once the user picks one side and then the other.
   */

  // When user clicks a word:
  const handleWordClick = (word: string) => {
    // If there's no definition selected yet, just set the word
    if (!selectedDefinition) {
      // If user clicked the same word again, unselect it
      if (selectedWord === word) {
        setSelectedWord(null);
      } else {
        setSelectedWord(word);
      }
    } else {
      // We have a definition selected, now user picks a word => do the check
      const isMatch = allCards.some(
        card => card.word === word && card.definition === selectedDefinition
      );
      handleMatch(isMatch);
    }
  };

  // When user clicks a definition:
  const handleDefinitionClick = (definition: string) => {
    // If there's no word selected yet, just set the definition
    if (!selectedWord) {
      // If user clicked the same definition again, unselect it
      if (selectedDefinition === definition) {
        setSelectedDefinition(null);
      } else {
        setSelectedDefinition(definition);
      }
    } else {
      // We have a word selected, now user picks a definition => do the check
      const isMatch = allCards.some(
        card => card.word === selectedWord && card.definition === definition
      );
      handleMatch(isMatch);
    }
  };

  // Retry: Reset all game state
  const handleRetry = useCallback(() => {
    setScore(0);
    setTimeLeft(90);
    setIsGameActive(true);
    setGameStartTime(Date.now());

    setSelectedWord(null);
    setSelectedDefinition(null);
    setMatchedWord(null);
    setMatchedDefinition(null);
    setTempMatchedPairs(new Set());

    showNewCards(allCards);
  }, [allCards, showNewCards]);

  if (error) {
    return (
      <div className="h-screen bg-primary flex items-center justify-center">
        <div className="text-center">
          <p className="text-2xl text-white mb-4">{error}</p>
          <button onClick={onExit} className="button-3d">Go Back</button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-primary flex flex-col">
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-8">
        <Header showBackButton onBack={onExit} score={score} />

        {/* Timer */}
        <div className="text-center font-luckiest text-6xl text-highlight mb-4">
          {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
        </div>

        {/* If time is up, show game-over modal. Lives do NOT cause game over. */}
        {!isGameActive && (
          <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
            <div className="bg-blue-800 p-8 rounded-[2px] text-center text-white">
              <h2 className="text-4xl font-bold mb-4">Game Over!</h2>
              <p className="text-2xl mb-2">Final Score: {score}</p>
              <p className="text-lg text-white/80 mb-6">Time's up!</p>
              <div className="space-x-4">
                <button
                  onClick={handleRetry}
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <RotateCcw className="w-5 h-5" />
                  Play Again
                </button>
                <button
                  onClick={onExit}
                  className="button-3d flex items-center gap-2 inline-flex"
                >
                  <ArrowLeft className="w-5 h-5" />
                  Exit
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-y-auto scrollbar-hide">
          <div className="flex gap-2 justify-center items-start mt-8 pb-8">
            {/* Word Buttons */}
            <div className="flex flex-col gap-1">
              {currentWords.map(word => (
                <KeyboardButton
                  key={word}
                  label={word}
                  onClick={() => handleWordClick(word)}
                  isActive={selectedWord === word}
                  isMatched={tempMatchedPairs.has(word)}
                />
              ))}
            </div>
            
            {/* Definition Buttons */}
            <div className="flex flex-col gap-1">
              {currentDefinitions.map(definition => {
                // If we find the word associated with this definition, we can check matched state
                const matchingWord = allCards.find(
                  (card) => card.definition === definition
                )?.word || "";
                return (
                  <LongKeyboardButton
                    key={definition}
                    label={definition}
                    onClick={() => handleDefinitionClick(definition)}
                    isActive={selectedDefinition === definition}
                    isMatched={tempMatchedPairs.has(matchingWord)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
