import React, { useEffect, useState } from 'react';
import { CubePiece as CubePieceType } from '../types/cube';

interface CubePieceProps {
  position: CubePieceType['position'];
  colors: CubePieceType['colors'];
}

export const CubePiece: React.FC<CubePieceProps> = ({ position, colors }) => {
  // Default offset is set by screen size
  const [offset, setOffset] = useState(100);

  useEffect(() => {
    const updateOffset = () => {
      const w = window.innerWidth;
      if (w <= 400) {
        // Very small screens => offset = 30
        setOffset(30);
      } else if (w <= 500) {
        // Mid-range screens => offset = 40
        setOffset(40);
      } else {
        // Normal/larger screens => offset = 80 (instead of 100)
        setOffset(80);
      }
    };

    // Run once on mount:
    updateOffset();

    // (Optional) also respond to window resizing:
    window.addEventListener('resize', updateOffset);
    return () => window.removeEventListener('resize', updateOffset);
  }, []);

  return (
    <div
      className="cube-piece"
      style={{
        transform: `translate3d(
          ${position[0] * offset}px,
          ${position[1] * offset}px,
          ${position[2] * offset}px
        )`,
      }}
    >
      {colors.map((color, i) => (
        <div
          key={i}
          className={`face face-${i}`}
          style={{ backgroundColor: color }}
        />
      ))}
    </div>
  );
};
