import React, { useEffect, useRef, useState, useCallback } from 'react';

interface TetrisGameProps {
  isCorrect: boolean | null;
  onGameOver: () => void;
  gameOver: boolean;
  onReset?: () => void;
}

// WIDER BOARD: 14 columns x 20 rows => 420 wide x 600 high
const COLS = 14;
const ROWS = 20;
const BLOCK_SIZE = 30;

const SHAPES = [
  [[1, 1, 1], [0, 1, 0]], // T-shape
  [[1, 1], [1, 1]],       // O-shape
  [[1, 1, 0], [0, 1, 1]], // Z-shape
  [[0, 1, 1], [1, 1, 0]], // S-shape
  [[1, 1, 1, 1]],         // I-shape
  [[1, 1, 1], [1, 0, 0]], // L-shape
  [[1, 1, 1], [0, 0, 1]], // J-shape
];

const SPEEDS = {
  NORMAL: 500,
  FAST: 50,     // correct => piece speeds up
  FALLING: 50,  // wrong => piece speeds up
  SLOW: 1000,
};

export default function TetrisGame({
  isCorrect,
  onGameOver,
  gameOver,
  onReset
}: TetrisGameProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();
  const lastUpdateRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // ------------------- Board State -------------------
  const [grid, setGrid] = useState<number[][]>(() =>
    Array.from({ length: ROWS }, () => Array(COLS).fill(0))
  );

  // Random shape
  const [currentShape, setCurrentShape] = useState<number[][]>(() =>
    SHAPES[Math.floor(Math.random() * SHAPES.length)]
  );
  // Place piece near center horizontally
  const [shapeX, setShapeX] = useState<number>(
    Math.floor(COLS / 2) - Math.floor(currentShape[0].length / 2)
  );
  const [shapeY, setShapeY] = useState<number>(0);

  // Speed/Auto-arrange
  const [gameSpeed, setGameSpeed] = useState<number>(SPEEDS.NORMAL);
  const [autoArrange, setAutoArrange] = useState<boolean>(false);

  // Ghost piece
  const [targetPosition, setTargetPosition] = useState<{
    x: number;
    y: number;
    shape: number[][];
  } | null>(null);

  const [fastFall, setFastFall] = useState<boolean>(false);
  const [movementQueue, setMovementQueue] = useState<
    Array<{ type: 'move' | 'rotate'; value?: number }>
  >([]);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

  // Track timing
  const [startTime, setStartTime] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(null);

  // ------------------- localIsCorrect to avoid leftover wrong speed -------------------
  const [localIsCorrect, setLocalIsCorrect] = useState<boolean | null>(isCorrect);

  // Whenever the parent changes isCorrect, update local
  useEffect(() => {
    setLocalIsCorrect(isCorrect);
  }, [isCorrect]);

  // ------------------- Reset Game -------------------
  const resetGame = useCallback(() => {
    if (!onReset) return;

    // Clear board
    setGrid(Array.from({ length: ROWS }, () => Array(COLS).fill(0)));
    // Random new shape
    const newShape = SHAPES[Math.floor(Math.random() * SHAPES.length)];
    setCurrentShape(newShape);
    setShapeX(Math.floor(COLS / 2) - Math.floor(newShape[0].length / 2));
    setShapeY(0);

    setGameSpeed(SPEEDS.NORMAL);
    setAutoArrange(false);
    setTargetPosition(null);
    setFastFall(false);
    setMovementQueue([]);
    setShouldUpdate(true);
    setLocalIsCorrect(null);

    onReset();
  }, [onReset]);

  // If game is over, stop updating
  useEffect(() => {
    if (gameOver) {
      setShouldUpdate(false);
    }
  }, [gameOver]);

  // Track startTime / duration
  useEffect(() => {
    if (!gameOver && startTime === null) {
      setStartTime(Date.now());
    } else if (gameOver && startTime !== null) {
      setDuration(Date.now() - startTime);
    }
  }, [gameOver, startTime]);

  useEffect(() => {
    if (duration !== null) {
      console.log(`Game duration: ${duration / 1000} seconds`);
    }
  }, [duration]);

  // ------------------- Canvas Scaling -------------------
  const updateCanvasSize = useCallback(() => {
    if (!containerRef.current || !canvasRef.current) return;
    const container = containerRef.current;
    const canvas = canvasRef.current;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const gameAspectRatio = COLS / ROWS; // 14/20
    const containerAspectRatio = containerWidth / containerHeight;

    let newScale;
    if (containerAspectRatio > gameAspectRatio) {
      newScale = containerHeight / (ROWS * BLOCK_SIZE);
    } else {
      newScale = containerWidth / (COLS * BLOCK_SIZE);
    }

    canvas.style.transform = `scale(${newScale})`;
    canvas.style.transformOrigin = "center center";
  }, []);

  // ------------------- Rotations -------------------
  const rotate = useCallback((shape: number[][], times: number = 1): number[][] => {
    let rotated = [...shape];
    for (let i = 0; i < times; i++) {
      const rows = rotated.length;
      const cols = rotated[0].length;
      const newRotated = Array.from({ length: cols }, () => Array(rows).fill(0));

      for (let r = 0; r < rows; r++) {
        for (let c = 0; c < cols; c++) {
          newRotated[c][rows - 1 - r] = rotated[r][c];
        }
      }
      rotated = newRotated;
    }
    return rotated;
  }, []);

  // ------------------- Collision Check -------------------
  const collide = useCallback(
    (shape: number[][], x: number, y: number): boolean => {
      for (let row = 0; row < shape.length; row++) {
        for (let col = 0; col < shape[row].length; col++) {
          if (shape[row][col]) {
            const newY = y + row;
            const newX = x + col;
            // out of bounds or collides with locked block
            if (
              newX < 0 ||
              newX >= COLS ||
              newY >= ROWS ||
              (newY >= 0 && grid[newY][newX])
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [grid]
  );

  // ------------------- Remove Full Lines Right Away -------------------
  const removeFullLines = useCallback((tempGrid: number[][]): number[][] => {
    // filter out full rows
    let newGrid = tempGrid.filter(row => !row.every(cell => cell === 1));
    const linesCleared = ROWS - newGrid.length;

    // add empty rows on top
    while (newGrid.length < ROWS) {
      newGrid.unshift(Array(COLS).fill(0));
    }
    return newGrid;
  }, []);

  // ------------------- Spawn New Shape -------------------
  const spawnNewShape = useCallback(() => {
    const newShape = SHAPES[Math.floor(Math.random() * SHAPES.length)];
    const newX = Math.floor(COLS / 2) - Math.floor(newShape[0].length / 2);

    if (collide(newShape, newX, 0)) {
      onGameOver();
      return false;
    }

    // next piece => normal speed, no leftover
    setGameSpeed(SPEEDS.NORMAL);
    setFastFall(false);
    setAutoArrange(false);
    setTargetPosition(null);
    setMovementQueue([]);

    setCurrentShape(newShape);
    setShapeX(newX);
    setShapeY(0);

    return true;
  }, [collide, onGameOver]);

  // ------------------- Lock Shape: Merge + Clear Lines Immediately -------------------
  const lockShape = useCallback(() => {
    // 1) Copy the grid
    let newGrid = grid.map(row => [...row]);

    let pieceLocked = false;
    // 2) Merge the piece
    for (let r = 0; r < currentShape.length; r++) {
      for (let c = 0; c < currentShape[r].length; c++) {
        if (currentShape[r][c]) {
          const putY = shapeY + r;
          const putX = shapeX + c;
          if (putY >= 0 && putY < ROWS && putX >= 0 && putX < COLS) {
            newGrid[putY][putX] = 1;
            pieceLocked = true;
          }
        }
      }
    }

    if (pieceLocked) {
      // 3) remove full lines immediately
      newGrid = removeFullLines(newGrid);

      // 4) update the grid
      setGrid(newGrid);

      // 5) reset leftover timer so new piece doesn't drop instantly
      lastUpdateRef.current = performance.now();

      // 6) reset localIsCorrect => next piece won't see leftover "false"
      setLocalIsCorrect(null);

      // 7) spawn new shape at normal speed
      spawnNewShape();

      return true;
    }
    return false;
  }, [
    grid,
    currentShape,
    shapeX,
    shapeY,
    removeFullLines,
    spawnNewShape
  ]);

  // ------------------- Move Down -------------------
  const moveDown = useCallback(() => {
    if (!collide(currentShape, shapeX, shapeY + 1)) {
      setShapeY(y => y + 1);
      return true;
    }
    return false;
  }, [currentShape, shapeX, shapeY, collide]);

  // ------------------- findBestPosition (auto-arrange) -------------------
  const findBestPosition = useCallback(() => {
    let bestScore = -Infinity;
    let bestX = shapeX;
    let bestY = shapeY;
    let bestRotation = 0;
    let bestShape = currentShape;

    for (let rotation = 0; rotation < 4; rotation++) {
      const rotated = rotate(currentShape, rotation);

      for (let x = -2; x < COLS + 2; x++) {
        let y = shapeY;
        while (!collide(rotated, x, y + 1)) {
          y++;
        }
        if (!collide(rotated, x, y)) {
          // place piece in a temporary grid
          const tempGrid = grid.map(row => [...row]);
          for (let r = 0; r < rotated.length; r++) {
            for (let c = 0; c < rotated[r].length; c++) {
              if (
                rotated[r][c] &&
                y + r >= 0 &&
                y + r < ROWS &&
                x + c >= 0 &&
                x + c < COLS
              ) {
                tempGrid[y + r][x + c] = 1;
              }
            }
          }

          // naive scoring: lines
          let lineCount = 0;
          for (let row = 0; row < ROWS; row++) {
            if (tempGrid[row].every(val => val === 1)) {
              lineCount++;
            }
          }
          const score = lineCount * 100 - (ROWS - y) * 2;

          if (score > bestScore) {
            bestScore = score;
            bestX = x;
            bestY = y;
            bestRotation = rotation;
            bestShape = rotated;
          }
        }
      }
    }

    const newQueue: Array<{ type: 'move' | 'rotate'; value?: number }> = [];
    if (bestRotation > 0) {
      newQueue.push({ type: 'rotate', value: bestRotation });
    }
    const moveX = bestX - shapeX;
    if (moveX !== 0) {
      newQueue.push({ type: 'move', value: moveX });
    }
    setMovementQueue(newQueue);

    return { x: bestX, y: bestY, shape: bestShape };
  }, [currentShape, shapeX, shapeY, rotate, collide, grid]);

  // ------------------- localIsCorrect => Speed Changes -------------------
  useEffect(() => {
    if (gameOver) return;

    if (localIsCorrect === true) {
      // correct => piece speeds up + auto-arrange
      setAutoArrange(true);
      setGameSpeed(SPEEDS.FAST);
      const bestPos = findBestPosition();
      setTargetPosition(bestPos);
      setFastFall(true);
    } else if (localIsCorrect === false) {
      // wrong => piece speeds up, no auto-arrange
      setAutoArrange(false);
      setGameSpeed(SPEEDS.FALLING);
      setTargetPosition(null);
      setFastFall(true);
    }
    // else if (localIsCorrect===null) => do nothing
  }, [localIsCorrect, findBestPosition, gameOver]);

  // ------------------- Movement Queue Execution -------------------
  const executeNextMovement = useCallback(() => {
    if (movementQueue.length === 0) return false;

    const [nextMove, ...rest] = movementQueue;
    if (nextMove.type === 'rotate') {
      const rotated = rotate(currentShape, nextMove.value || 1);
      if (!collide(rotated, shapeX, shapeY)) {
        setCurrentShape(rotated);
      }
    } else if (nextMove.type === 'move') {
      const step = Math.sign(nextMove.value || 0);
      const newX = shapeX + step;
      if (!collide(currentShape, newX, shapeY)) {
        setShapeX(newX);
      }
    }

    setMovementQueue(q => q.slice(1));
    return true;
  }, [movementQueue, currentShape, shapeX, shapeY, rotate, collide]);

  // ------------------- Drawing Everything -------------------
  const draw = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      // background
      ctx.fillStyle = "#FF1C60";
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      // locked blocks
      for (let y = 0; y < ROWS; y++) {
        for (let x = 0; x < COLS; x++) {
          if (grid[y][x]) {
            ctx.fillStyle = "#fff";
            ctx.fillRect(
              x * BLOCK_SIZE,
              y * BLOCK_SIZE,
              BLOCK_SIZE - 1,
              BLOCK_SIZE - 1
            );
          }
        }
      }

      // current falling piece
      for (let r = 0; r < currentShape.length; r++) {
        for (let c = 0; c < currentShape[r].length; c++) {
          if (currentShape[r][c]) {
            ctx.fillStyle = "#fff";
            ctx.fillRect(
              (shapeX + c) * BLOCK_SIZE,
              (shapeY + r) * BLOCK_SIZE,
              BLOCK_SIZE - 1,
              BLOCK_SIZE - 1
            );
          }
        }
      }

      // ghost => now light grey
      if (autoArrange && targetPosition) {
        ctx.fillStyle = "rgba(200, 200, 200, 0.5)"; // changed from green to light grey
        for (let r = 0; r < targetPosition.shape.length; r++) {
          for (let c = 0; c < targetPosition.shape[r].length; c++) {
            if (targetPosition.shape[r][c]) {
              ctx.fillRect(
                (targetPosition.x + c) * BLOCK_SIZE,
                (targetPosition.y + r) * BLOCK_SIZE,
                BLOCK_SIZE - 1,
                BLOCK_SIZE - 1
              );
            }
          }
        }
      }

      // game over overlay
      if (gameOver) {
        ctx.fillStyle = "rgba(255, 28, 96, 0.5)";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      }
    },
    [grid, currentShape, shapeX, shapeY, autoArrange, targetPosition, gameOver]
  );

  // ------------------- Main Animation Loop -------------------
  const update = useCallback(
    (timestamp: number) => {
      if (!lastUpdateRef.current) lastUpdateRef.current = timestamp;

      const elapsed = timestamp - lastUpdateRef.current;
      const currentSpeed = fastFall ? SPEEDS.FAST : gameSpeed;

      if (elapsed > currentSpeed && !gameOver && shouldUpdate) {
        if (autoArrange && targetPosition) {
          if (movementQueue.length > 0) {
            executeNextMovement();
          } else if (shapeY < targetPosition.y) {
            setShapeY(y => y + 1);
          } else {
            // lock
            lockShape();
          }
        } else {
          // normal falling
          if (!moveDown()) {
            lockShape();
          }
        }

        lastUpdateRef.current = timestamp;
      }

      const ctx = canvasRef.current?.getContext("2d");
      if (ctx) {
        draw(ctx);
      }

      requestRef.current = requestAnimationFrame(update);
    },
    [
      gameSpeed,
      gameOver,
      shouldUpdate,
      autoArrange,
      targetPosition,
      moveDown,
      lockShape,
      draw,
      fastFall,
      movementQueue,
      executeNextMovement
    ]
  );

  // start animation
  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [update]);

  // scale on resize
  useEffect(() => {
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, [updateCanvasSize]);

  return (
    <div
      ref={containerRef}
      className="tetris-container flex flex-col items-center justify-center h-full"
    >
      <canvas
        ref={canvasRef}
        // 14 columns => 420 wide, 20 rows => 600 high
        width={COLS * BLOCK_SIZE}
        height={ROWS * BLOCK_SIZE}
        className="rounded-[2px] bg-accent shadow-brutal"
      />
    </div>
  );
}
