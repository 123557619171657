import React, { useCallback, useState } from 'react';
import Papa from 'papaparse';
import { FlashCard, FlashCardSet } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Loader2 } from 'lucide-react';
import { removeDuplicateSets, getUniqueSetName } from '../utils/setUtils';
import { detectLanguage } from '../utils/languageDetection';

interface FileUploadProps {
  onCardsUpload: (cards: FlashCard[], fileName: string) => void;
}

export default function FileUpload({ onCardsUpload }: FileUploadProps) {
  const { user } = useAuth();
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !user) return;

    setUploading(true);

    Papa.parse<string[]>(file, {
      complete: async (results) => {
        try {
          const data = results.data;

          // Expecting four columns: Group Name, Set Name, Word, Definition
          const requiredColumns = 4;

          // Process data
          const groupedData: Record<string, { groupName: string | null; setName: string; cards: { word: string; definition: string; language: string }[] }> = {};

          data.forEach((row, index) => {
            // Skip empty rows
            if (row.length < 4) {
              console.warn(`Row ${index + 1} skipped: Expected at least 4 columns, found ${row.length}`);
              return;
            }

            const groupName = row[0]?.trim() || null;
            const setName = row[1]?.trim();
            const word = row[2]?.trim();
            const definition = row[3]?.trim();
            // Get language from CSV or detect it
            const language = row[4]?.trim() || detectLanguage(word);

            if (!setName || !word || !definition) {
              console.warn(`Row ${index + 1} skipped: Missing required fields.`);
              return;
            }

            const key = `${groupName || 'Ungrouped'}|${setName}`;

            if (!groupedData[key]) {
              groupedData[key] = {
                groupName,
                setName,
                cards: [],
              };
            }

            groupedData[key].cards.push({ word, definition, language });
          });

          // Remove sets with no cards
          Object.keys(groupedData).forEach(key => {
            if (groupedData[key].cards.length === 0) {
              delete groupedData[key];
            }
          });

          if (Object.keys(groupedData).length === 0) {
            throw new Error('No valid sets found in the CSV file. Please check the format.');
          }

          // Iterate over each group to create sets and flashcards
          for (const key of Object.keys(groupedData)) {
            const { groupName, setName, cards } = groupedData[key];

            // Create the set
            const { data: setData, error: setError } = await supabase
              .from('flashcard_sets')
              .insert({
                name: setName,
                created_by: user.id,
                is_public: true,
                group_name: groupName,
              })
              .select()
              .single();

            if (setError) throw setError;

            // Prepare flashcards
            const flashCards: FlashCard[] = cards.map(card => ({
              id: crypto.randomUUID(),
              setId: setData.id,
              word: card.word,
              definition: card.definition,
              language: card.language,
              easeFactor: 2.5,
              interval: 0,
              repetitions: 0,
              nextReview: new Date(),
            }));

            // Insert flashcards into the database
            const { error: cardsError } = await supabase
              .from('flashcards')
              .insert(flashCards.map(card => ({
                set_id: card.setId,
                word: card.word,
                definition: card.definition,
                language: card.language
              })));

            if (cardsError) throw cardsError;
          }

          // Reset the input and notify parent
          event.target.value = '';
          onCardsUpload(
            data.map(row => ({
              word: row[2]?.trim() || '',
              definition: row[3]?.trim() || '',
              language: row[4]?.trim() || detectLanguage(row[2]?.trim() || ''),
              id: crypto.randomUUID(),
              easeFactor: 2.5,
              interval: 0,
              repetitions: 0,
              nextReview: new Date(),
            })),
            file.name.replace('.csv', '')
          );

          // Optionally, reload the page or update state to reflect new sets
          window.location.href = window.location.href;
        } catch (error) {
          console.error('Error uploading cards:', error);
          alert(error instanceof Error ? error.message : 'Failed to upload cards. Please try again.');
          event.target.value = '';
        } finally {
          setUploading(false);
        }
      },
      header: false,
      skipEmptyLines: true,
      transform: (value) => value.trim(),
    });
  }, [onCardsUpload, user]);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      const input = document.getElementById('file-upload') as HTMLInputElement;
      if (input) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        input.files = dataTransfer.files;
        input.dispatchEvent(new Event('change', { bubbles: true }));
      }
    } else {
      alert('Please upload a CSV file');
    }
  }, []);

  return (
    <div className="w-full">
      <div
        className={`drop-zone text-center relative ${uploading ? 'opacity-50' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <Loader2 className="w-8 h-8 animate-spin text-white" />
          </div>
        )}
        <p className="text-xl mb-4">
          Drop your file here or click the <span className="font-bold">Upload</span> button above.
        </p>
        <p className="text-white/80">
          CSV file with Group Name, Set Name, Words in the third column, and Definitions in the fourth column.
          Optional fifth column for language codes (e.g., "en", "es", "ja").
        </p>
        <input
          id="file-upload"
          type="file"
          accept=".csv,text/csv"
          className="hidden"
          onChange={handleFileUpload}
          disabled={uploading}
        />
      </div>
    </div>
  );
}